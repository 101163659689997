import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/home/index.vue'

const router = createRouter({
  history: createWebHistory(), //工作模式 ，还有一种是hash
  routes: [
    {
      path: '/',
      redirect: '/api/user/oauth' //重定向
    },
    {
      path: '/api/user/oauth', //pages下的vue文件的路径
      component: Home //pages下的vue文件
    }
  ]
})
export default router //向外抛出
